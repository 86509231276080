<template>
  <div class="home">
    <div id="Top">
      <img id="ImgOne" src="http://29299667.s21i.faiusr.com/2/ABUIABACGAAgrcrBlAYo4qWDpgcwgA84oAY.jpg.webp" />
    </div>
    <div id="body">

      <div id="Ming"><img id="ImgTow"
          src="http://29299667.s21i.faiusr.com/4/ABUIABAEGAAgqJKHlAYo2ozi5wEwaThp!60x60.png.webp" />易学名家</div>
      <div id="div1">
        <ul class="ul1">
          <li class="li1" v-for="(Celebrity, CelebrityIndex) in CelebrityList" :key="CelebrityIndex">
            <img class="img1" :src="Celebrity.CelebrityImg" alt="">
            <p>{{ Celebrity.CelebrityName }}</p>
          </li>

        </ul>
      </div>
    </div>
    <h1 class="app_name">易卜 APP 即将上线,敬请期待</h1>
    <div class="app_pic">
      <img class="app_pic1" src="../assets/ic_1.jpg" />
      <img class="app_pic1" src="../assets/ic_2.jpg" />
      <img class="app_pic1" src="../assets/ic_3.jpg" />
      <img class="app_pic1" src="../assets/ic_4.jpg" />


    </div>
    <div id="bottom">
      <img id="ImgFour" src="http://29299667.s21i.faiusr.com/4/ABUIABAEGAAg78nclAYohJW45QMwgA841wI!1500x1500.png.webp" />
    </div>


    <div>苏ICP备2022035903号 | 江苏易小生文化传播有限公司
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  data() {
    return {
      CelebrityList: [{ CelebrityImg: "http://29299667.s21i.faiusr.com/2/ABUIABACGAAg2JC2lAYo6NH0sgMw3gI4wgM!300x300.jpg.webp", CelebrityName: "周文王" },
      { CelebrityImg: "http://29299667.s21i.faiusr.com/2/ABUIABACGAAg2JC2lAYo_Om8RzDeAjjCAw!300x300.jpg.webp", CelebrityName: "孔子" },
      { CelebrityImg: "http://29299667.s21i.faiusr.com/2/ABUIABACGAAg2JC2lAYohNDYwQcw3gI4wgM!300x300.jpg.webp", CelebrityName: "周公" },
      { CelebrityImg: "http://29299667.s21i.faiusr.com/2/ABUIABACGAAg2JC2lAYoq8DAnwMw3gI4wgM!300x300.jpg.webp", CelebrityName: "黄帝" },
      { CelebrityImg: "http://29299667.s21i.faiusr.com/2/ABUIABACGAAgnZW2lAYogpCgyQMwmAI4_gE.jpg.webp", CelebrityName: "神农" },
      { CelebrityImg: "http://29299667.s21i.faiusr.com/2/ABUIABACGAAgrJS2lAYoztm6yQUwmAI4_gE.jpg.webp", CelebrityName: "伏羲" },
      { CelebrityImg: "http://29299667.s21i.faiusr.com/2/ABUIABACGAAgzJW2lAYo6LrfzwEwmAI4_gE.jpg.webp", CelebrityName: "老子" }],
      src1: require("../assets/ic_1.jpg")

    }
  },
  methods: {
    WidthHeight() {
      let screenWidth = window.document.documentElement.clientWidth || window.innerWidth;
      document.getElementById("ImgOne").style.width = screenWidth + "px";
      if (screenWidth > 2560) {
        screenWidth = 2560
      }
      document.getElementById("ImgFour").style.width = screenWidth * 0.6859375 + "px";
      var oDiv = document.getElementById("div1");
      oDiv.style.width = screenWidth - 10 + "px";
      var oUl = document.getElementsByClassName("ul1")[0];
      var oLi = document.getElementsByClassName("li1");
      for (let i = 0; i < oLi.length; i++) {
        oLi[i].style.width = screenWidth * 0.15859375 + "px";
      }
      var timer = null;
      var iSpeed = 8;
      //复制一遍ul
      oUl.innerHTML += oUl.innerHTML;
      //ul的宽度是所有li宽度之和，复制ul之后的整个ul的宽度就是li的长度乘以一个li的宽度
      oUl.style.width = oLi.length * oLi[0].offsetWidth + "px";

      function fnMove() {
        //图片向左移动时的条件，即在div里的ul的offsetLeft小于一个ul的宽度
        if (oUl.offsetLeft < -oUl.offsetWidth / 2) {
          //将整个复制的ul向右拖拽直至整个ul中的第一张图归位到起点
          oUl.style.left = 0;
        }
        //图片向右移动时的条件，即在div里的ul的offsetLeft大于等于0
        else if (oUl.offsetLeft >= 0) {
          //将整个复制的ul向左拖拽直至整个ul中的第一张图归位到起点
          oUl.style.left = -oUl.offsetWidth / 2 + "px";
        }
        //给ul一个速度让整个ul的offsetLeft增加或减少，速度为正则向右移动，速度为负则向左移动
        oUl.style.left = oUl.offsetLeft + iSpeed + "px";
      }
      iSpeed = 2;
      //自动滚动
      timer = setInterval(fnMove, 30);
      //当鼠标移动到div里面的时候图片滚动暂停，此时清除定时器即可。
      oDiv.onmouseover = function () {
        clearInterval(timer);
      }
      //当鼠标移出div的时候图片继续滚动，此时重新加载定时器。
      oDiv.onmouseout = function () {
        timer = setInterval(fnMove, 30);
      }

    }
  },
  mounted() {
    this.WidthHeight()
  }
}
</script>
<style lang="css" src="../assets/HomeCSS.css"></style>
